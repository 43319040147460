<template>
    <div>
        <BackNavigatorBar @back="navigatorBack"/>
        <CSTabBar
            :tabbar="tabbar"
            :checkedTab="checkedTab"
            @changeTabBar="changeTabBar"
        ></CSTabBar>

        <div class="tab-view" v-show="checkedTab == 1">
            <div
                    :class="['field-item']"
            >
                <div class="field-item-label">首次接触时间</div>
                <div>
                    <CSSelect>
                        <el-date-picker
                                :disabled="isDisabled"
                                v-model="customerInfo.firstCommunicationTime"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择时间"
                                prefix-icon="el-icon-time"
                        ></el-date-picker>
                    </CSSelect>
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">首次接触沟通记录</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="text"
                            v-model="customerInfo.firstCommunicationRecord"
                            class="cs-input"
                            placeholder="限100个字"
                            maxlength="100"
                    />
                </div>
            </div>




            <div class="field-item">
                <div class="field-item-label">企业名称</div>
                <div>
                    <input
                        type="text"
                        class="cs-input"
                        :disabled="isDisabled"
                        v-model="customerInfo.name"
                        placeholder="限30个字"
                        maxlength="30"
                    />
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">所属行业</div>
                <div>
                    <CSSelect height="30px">
                        <select v-model="customerInfo.industry" :disabled="isDisabled">
                            <option value="">请选择</option>
                            <option
                                v-for="(val, type) in INDUSTRY_TYPES"
                                :key="type"
                                :value="type"
                            >
                                {{ val }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">详细地址</div>
                <div>
                    <input
                        type="text"
                        class="cs-input"
                        :disabled="isDisabled"
                        v-model="customerInfo.address"
                        placeholder="限30个字"
                        maxlength="30"
                    />
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">意向程度</div>
                <div>
                    <CSRadio
                        :disabled="isDisabled"
                        v-model="customerInfo.intention"
                        :items="radioRange"
                        style="background-color: white !important;"
                    ></CSRadio>
                    <svg t="1650454871363" style="margin-left: 10px" @click="lookLeadsLevels" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3312" width="18" height="18">
                        <path d="M-0.056886 511.971557a511.971557 511.971557 0 1 1 511.971557 511.971557 512.5973 512.5973 0 0 1-511.971557-511.971557z m113.771457 0a398.2001 398.2001 0 1 0 398.2001-398.2001 398.712072 398.712072 0 0 0-398.2001 398.2001z m344.215544 266.964724a59.673129 59.673129 0 0 1 0-85.954336 58.1941 58.1941 0 0 1 43.062497-17.065718 61.777901 61.777901 0 0 1 44.655296 17.065718 58.307872 58.307872 0 0 1 17.520805 42.89184 59.616244 59.616244 0 0 1-17.520805 43.858897 65.930559 65.930559 0 0 1-44.655296 16.667518 58.876729 58.876729 0 0 1-43.005611-17.463919z m-2.389201-141.759235v-11.377146a169.860786 169.860786 0 0 1 16.041776-78.04722A423.059163 423.059163 0 0 1 564.704628 446.552969l9.556802-11.377145a87.945336 87.945336 0 0 0 21.44592-56.544415 91.586023 91.586023 0 0 0-160.815955-52.619299 132.202433 132.202433 0 0 0-19.910005 78.84362H324.191767a182.034331 182.034331 0 0 1 51.766013-138.459863A191.022277 191.022277 0 0 1 515.214044 216.165769a181.351703 181.351703 0 0 1 125.831231 42.152324 143.636465 143.636465 0 0 1 47.840898 114.738515 156.833954 156.833954 0 0 1-29.580579 97.900339q-12.685517 14.505861-68.262874 62.915616a120.313316 120.313316 0 0 0-31.799122 39.080495 115.876229 115.876229 0 0 0-11.946003 52.619299v11.377146z" fill="#999999" p-id="3313"></path>
                    </svg>
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">客户来源</div>
                <div>
                    <CSSelect height="30px">
                        <select v-model="customerInfo.source" :disabled="isDisabled">
                            <option value="">请选择</option>
                            <option
                                v-for="(val, type) in CUSTOMER_SOURCES"
                                :key="type"
                                :value="type"
                            >
                                {{ val }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
            </div>






            <div class="field-item">
                <div class="field-item-label">租/购</div>
                <div>
                    <CSRadio
                            :disabled="isDisabled"
                            :items="leaseMethodList"
                            v-model="customerInfo.leaseMethod"
                            style="background-color: white !important;"
                    />
<!--                    <img-->
<!--                            @click="lookLeadsLevels"-->
<!--                            style="-->
<!--                    width: 20px;-->
<!--                    height: 20px;-->
<!--                    margin-left: 15px;-->
<!--                    vertical-align: middle;-->
<!--                    margin-top: 5px;-->
<!--                  "-->
<!--                            src="../../assets/icon/question.png"-->
<!--                            alt=""-->
<!--                    />-->
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">认知途径</div>
                <div>
                    <CSSelect>
                        <select v-model="customerInfo.cognitivePathway"  :disabled="isDisabled">
                            <option value="">请选择</option>
                            <option
                                    v-for="(val, type) in CONITIVE_PATHWAY_NEW"
                                    :key="type"
                                    :value="type"
                            >
                                {{ val }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">是否已来访</div>
                <div>
                    <CSRadio
                            :disabled="isDisabled"
                            :items="cognitivePathwayList"
                            v-model="customerInfo.visited"
                            style="background-color: white !important;"
                    />
<!--                    <img-->
<!--                            @click="lookLeadsLevels"-->
<!--                            style="-->
<!--                    width: 20px;-->
<!--                    height: 20px;-->
<!--                    margin-left: 15px;-->
<!--                    vertical-align: middle;-->
<!--                    margin-top: 5px;-->
<!--                  "-->
<!--                            src="../../assets/icon/question.png"-->
<!--                            alt=""-->
<!--                    />-->
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">主营业务</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="text"
                            class="cs-input"
                            placeholder="限50个字"
                            v-model="customerInfo.mainBusiness"
                            maxlength="50"
                    />
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">现有办公地址所属区位</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="text"
                            class="cs-input"
                            placeholder="限10个字"
                            v-model="customerInfo.affiliationLocation"
                            maxlength="10"
                    />
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">现有办公面积</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="number"
                            class="cs-input"
                            placeholder="保留两位小数"
                            v-model="customerInfo.officeArea"
                    />
                </div>
            </div>

            <div class="field-item">
                <div class="field-item-label">企业人数</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="number"
                            class="cs-input"
                            placeholder="限整数"
                            v-model="customerInfo.companyNumber"
                    />
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">选址目的</div>
                <div>
                    <CSSelect>
                        <select v-model="customerInfo.purposeOfSiteSelection"  :disabled="isDisabled">
                            <option value="">请选择</option>
                            <option
                                    v-for="(val, type) in PURPOSE_OFSITE_SELECTION"
                                    :key="type"
                                    :value="type"
                            >
                                {{ val }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">需求面积</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="number"
                            class="cs-input"
                            placeholder="保留两位小数"
                            v-model="customerInfo.requiredArea"
                    />
                </div>
            </div>
            <div
                    :class="['field-item']"
            >
                <div class="field-item-label">预计用房时间</div>
                <div>
                    <CSSelect>
                        <el-date-picker
                                :disabled="isDisabled"
                                v-model="customerInfo.estimatedOccupancyTime"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择时间"
                                prefix-icon="el-icon-time"
                        ></el-date-picker>
                    </CSSelect>
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">关注点</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="text"
                            class="cs-input"
                            placeholder="限50个字"
                            v-model="customerInfo.focus"
                            maxlength="50"
                    />
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">回访记录</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="text"
                            class="cs-input"
                            placeholder="限50个字"
                            v-model="customerInfo.returnVisitRecord"
                            maxlength="50"
                    />
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-label">接待人</div>
                <div>
                    <input
                            :disabled="isDisabled"
                            type="text"
                            class="cs-input"
                            placeholder="限10个字"
                            v-model="customerInfo.receptionist"
                            maxlength="10"
                    />
                </div>
            </div>








            <div class="field-item" style="margin-top: 25px">
                <div class="field-item-label" style="vertical-align: top">
                    备注
                </div>
                <div>
                    <textarea
                        type="text"
                        :disabled="isDisabled"
                        v-model="customerInfo.comment"
                        class="cs-input"
                        placeholder="限100个字"
                        maxlength="100"
                    />
                </div>
            </div>
            <div class="field-item" v-if="!isDisabled">
                <div class="field-item-label"></div>
                <div>
                    <button class="btn btn-primary" @click="updateCustomerInfo">
                        保存
                    </button>
                </div>
            </div>
        </div>

        <div class="result-panel" v-if="checkedTab == 2">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th>跟进时间</th>
                        <th>跟进内容</th>
                        <th>跟进对象</th>
                        <th>跟进员工</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="followLogs && followLogs.length > 0">
                        <tr v-for="log in followLogs" :key="log.id">
                            <td>{{ log.time.substring(0, log.time.length - 3) }}</td>
                            <td>{{ log.content }}</td>
                            <td>{{ log.contacts }}</td>
                            <td>{{ log.follower }}</td>
                        </tr>
                    </template>
                </template>
            </CSTable>
            <Pagination></Pagination>
        </div>

        <div class="result-panel" v-if="checkedTab == 3">
            <CSTable>
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button class="btn btn-primary sticky-right" @click="openAddCustomerDialog">
                            添加联系人
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>联系人</th>
                        <th>电话</th>
                        <th>微信号</th>
                        <th>邮箱</th>
                        <th>职位</th>
                        <th>是否为决策人</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="contactList && contactList.length > 0">
                        <tr
                            v-for="contact in contactList"
                            :key="contact.id"
                        >
                            <td>
                                {{ contact.name }}
                                <span class="cs-tag warning" v-if="contact.defaultContacts">默认联系人</span>
                            </td>
                            <td>{{ contact.phone }}</td>
                            <td>{{ contact.wechatId || '-' }}</td>
                            <td>{{ contact.email || '-' }}</td>
                            <td>{{ contact.title || '-' }}</td>
                            <td>
                                {{ contact.decisionMaker ? "是" : "否" }}
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul
                                        class="dropdown-menu"
                                        style="width: 86px;"
                                    >
                                        <li
                                            v-if="!contact.defaultContacts"
                                            @click="
                                                    setDefaultContact(
                                                        contact.id
                                                    )
                                                "
                                        >
                                            <a
                                                style="width: 100%;"
                                            >设置为默认联系人</a>
                                        </li>
                                        <li
                                            @click="
                                                    openEditCustomerDialog(
                                                        contact
                                                    )
                                                "
                                        >
                                            <a
                                                style="width: 100%;"
                                            >修改</a>
                                        </li>
                                        <li
                                            @click="
                                                    deleteContact(contact.id)
                                                "
                                        >
                                            <a
                                                style="width: 100%;"
                                            >删除</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>
        </div>

        <CSDialog
            :dialogVisible="contactDialog.visible"
            :dialogWidth="728"
            @onClose="contactDialog.onCancel"
            @onConfirm="contactDialog.onConfirm"
            dialog-title="添加联系人"
        >
            <template v-slot:dialog-content>
                <div class="contact-dialog-content">
                    <div class="form-item">
                        <div class="form-item-label">姓名</div>
                        <div>
                            <input
                                type="text"
                                class="cs-input"
                                v-model="contactDialog.data.name"
                                placeholder="限10个字"
                                maxlength="10"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">电话</div>
                        <div>
                            <input
                                type="text"
                                class="cs-input"
                                v-model="contactDialog.data.phone"
                                placeholder="限11个字"
                                maxlength="11"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">微信号</div>
                        <div>
                            <CSRadio
                                width="20px" inner-width="12px"
                                v-model="isSamePhone"
                                :items="{ true: '同手机号', false: '其它' }"
                            />
                            <input
                                v-show="!isSamePhone"
                                type="text"
                                class="cs-input"
                                v-model="contactDialog.data.wechatId"
                                style="width: 240px; margin-left: 10px"
                                placeholder="限20个字"
                                maxlength="20"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">邮箱</div>
                        <div>
                            <input
                                type="text"
                                class="cs-input"
                                v-model="contactDialog.data.email"
                                placeholder="限30位"
                                maxlength="30"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">职位</div>
                        <div>
                            <input
                                type="text"
                                class="cs-input"
                                v-model="contactDialog.data.title"
                                placeholder="限5个字"
                                maxlength="5"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-item-label">是否为决策人</div>
                        <div>
                            <CSRadio
                                width="20px" inner-width="12px"
                                v-model="contactDialog.data.decisionMaker"
                                :items="{ true: '是', false: '否' }"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import CSRadio from "@/components/common/CSRadio";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination";
import BackNavigatorBar from "@/components/common/BackNavigatorBar";

import {
    queryCustomerInfoUrl,
    updateCustomerInfoUrl,
    queryCustomerFollowLogUrl,
    queryLeadContactsUrl,
    deleteContactUrl,
    addLeadContactsUrl,
    setupDefaultContactsUrl,
    editLeadContactsUrl,
} from "@/requestUrl";
import { INDUSTRY_TYPES, CUSTOMER_SOURCES,CONITIVE_PATHWAY_NEW,PURPOSE_OFSITE_SELECTION } from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
    name: "customerInfo",
    components: {
        CSTable,
        CSTabBar,
        CSSelect,
        CSRadio,
        CSDialog,
        Pagination,
        BackNavigatorBar,
    },
    props: {
        // 要查看的tab
        tabId: Number,
        // 查询的企业id
        leadId: Number,
        // 从那个客户池跳转过来的  1: 公有客户池  2：私有客户池  3：认租客户池
        sourceType: Number,
        // 客户信息所属的员工id
        staffId: Number,
    },
    data() {
        return {
            leaseMethodList:{
                1:'租赁',
                2:'购买'
            },
            cognitivePathwayList:{
                0:'否',
                1:'是'
            },
            CONITIVE_PATHWAY_NEW,
            INDUSTRY_TYPES,
            CUSTOMER_SOURCES,
            PURPOSE_OFSITE_SELECTION,
            tabbar: {
                1: {
                    name: "企业信息",
                },
                2: {
                    name: "跟进记录",
                },
                3: {
                    name: "联系人",
                },
            },
            //  选中的tab
            checkedTab: 1,
            radioRange: {
                1: "A",
                2: "B",
                3: "C",
                4: "D",
                5: "E",
            },
            isSamePhone: true,
            contactDialog: {
                visible: false,
                data: {
                    regionCode: this.$vc.getCurrentRegion().code,
                    leadId: this.leadId, // 线索ID，必填
                    name: "",
                    title: "",
                    phone: "",
                    wechatId: "",
                    email: "",
                    decisionMaker: null, // 是否决策人
                },
                onCancel: () => {
                    this.contactDialog.visible = false;
                },
                onConfirm() {},
            },
            customerInfo: {
                leadId: null,
                name: "",
                address: "",
                industry: "",
                source: "",
                intention: "",
                comment: "",


                receptionist:'',//接待人
                returnVisitRecord:'',//回访记录
                focus:'',//关注点
                estimatedOccupancyTime:'',// 预计用房时间
                requiredArea:'',// 需求面积
                purposeOfSiteSelection:'',//新加 选址目的 1:办公需求;2:生产需求;3仓库需求;4:商业配套需求
                companyNumber:'',//企业人数
                officeArea:'',//现有办公面积
                affiliationLocation:'',//办公地址所属区位
                mainBusiness:'',//主营业务
                visited:0,//是否来访
                cognitivePathway:"",//认知途径
                firstCommunicationRecord:'',//首次沟通记录
                firstCommunicationTime:'',//首次沟通时间
            },
            followLogs: [],
            contactList: [],
        };
    },
    computed: {
      isDisabled() {
        const {sourceType, staffId} = this,
              staffInfo = this.$vc.getCurrentStaffInfo();
        switch(sourceType) {
          case 1:
            return staffInfo.jobLevel !== 1;
          case 2:
            return staffInfo.id !== staffId;
          case 3:
            return true;
          default:
            return false;
        }
      }
    },
    watch: {
        isSamePhone(val) {
            this.contactDialog.data.wechatId =
                val ? this.contactDialog.data.phone : "";
        },
    },
    created() {
        this.changeTabBar(this.tabId, "checkedTab");
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this.queryFollowLog(_currentPage);
        });
    },
    methods: {
        navigatorBack() {
            this.$router.replace({name: ''})
        },
        // 打开添加联系人
        openAddCustomerDialog() {
            this.contactDialog.data = {
                regionCode: this.$vc.getCurrentRegion().code,
                leadId: this.leadId, // 线索ID，必填
                name: "",
                title: "",
                phone: "",
                wechatId: "",
                email: "",
                decisionMaker: null, // 是否决策人
            };
            this.isSamePhone = true;
            this.contactDialog.visible = true;
            this.contactDialog.onConfirm = () => {
                if (this.isSamePhone) {
                  this.contactDialog.data.wechatId = this.contactDialog.data.phone;
                }
                this.$fly
                    .post(addLeadContactsUrl, {
                        ...this.contactDialog.data,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast("添加成功");
                        this.contactDialog.onCancel();
                        this.queryCustomerContacts();
                    });
            };
        },
        /**
         * 打开修改联系人弹窗
         * @param {Object} contact 联系人信息
         *  */
        openEditCustomerDialog(contact) {
            this.$vc.copyObject(contact, this.contactDialog.data);
            this.isSamePhone = contact.phone === contact.wechatId;
            this.contactDialog.data.decisionMaker = contact.decisionMaker.toString();
            this.contactDialog.visible = true;
            this.contactDialog.onConfirm = () => {
                if (this.isSamePhone) {
                  this.contactDialog.data.wechatId = this.contactDialog.data.phone;
                }
                this.$fly
                    .post(editLeadContactsUrl, {
                        id: contact.id,
                        ...this.contactDialog.data,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast("修改成功");
                        this.contactDialog.onCancel();
                        this.queryCustomerContacts();
                    });
            };
        },
        /**
         * 设置默认联系人
         * @param {Number} id 联系人id
         *
         *  */
        setDefaultContact(id) {
            this.$CSDialog.confirm({
                title: '提示',
                message: "确定设为默认联系人吗？",
                onConfirm: () => {
                    this.$fly
                        .post(setupDefaultContactsUrl, {
                            id,
                            leadId: this.leadId,
                        })
                        .then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$CSDialog.instance.closeDialog();
                            this.$vc.toast("设置默认成功");
                            this.queryCustomerContacts();
                        });
                },
            });
        },
        /**
         * 删除联系人
         * @param {Number} id 联系人id
         *
         *  */
        deleteContact(id) {
            this.$CSDialog.confirm({
                title: '提示',
                message: "确定删除吗？",
                onConfirm: () => {
                    this.$fly
                        .post(deleteContactUrl, {
                            id,
                            leadId: this.leadId,
                        })
                        .then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$CSDialog.instance.closeDialog();
                            this.$vc.toast("删除成功");
                            this.queryCustomerContacts();
                        });
                },
            });
        },
        // 查询企业的联系人
        queryCustomerContacts() {
            this.$fly
                .get(queryLeadContactsUrl, {
                    leadId: this.leadId,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.contactList = res.data;
                });
        },
        // 获取跟进记录
        queryFollowLog(pageNo = 1) {
            this.$fly
                .get(queryCustomerFollowLogUrl, {
                    pageNo,
                    leadId: this.leadId,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.followLogs = res.data;
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            currentPage: pageNo,
                        });
                    }
                });
        },
        // 查看销售资源等级
        lookLeadsLevels() {
            this.$CSDialog.alert({
                width: 760,
                title: '意向程度说明',
                messageHtml: `
                <ul class="customer-levels-tips">
                    <li>A：准客户，7天内认租。</li>
                    <li>
                        B：谈判客户，已来项目查看，处于谈判中，30天内认租。
                    </li>
                    <li>C：意向客户，对项目认可，需持续跟进。</li>
                    <li>D：潜在客户，有租赁需求，需持续跟进。</li>
                    <li>E：暂无意向。</li>
                </ul>
            `,
            });
        },
        // 查询客户信息
        queryCustomerInfo() {
            this.$fly
                .get(queryCustomerInfoUrl, {
                    leadId: this.leadId,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.customerInfo = res.data;
                });
        },
        // 更新用户信息
        updateCustomerInfo() {
            this.$fly
                .post(
                    updateCustomerInfoUrl,
                    this.$vc.removeNullProperty(this.customerInfo)
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("修改成功");
                });
        },
        /**
         * 更改选中的tab
         * @param {Number} id 选中的tab
         * @param {Object} checkedName 更改的属性名
         *  */
        changeTabBar(id, checkedName) {
            this[checkedName] = id;
            const tabActivity = {
                1: this.queryCustomerInfo,
                2: this.queryFollowLog,
                3: this.queryCustomerContacts,
            };
            tabActivity[id]();
        },
    },
};
</script>

<style lang="stylus" scoped>
.tab-view
    padding 30px
    box-sizing border-box
    background #fff
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    min-height 680px
    .field-item
        font-size 14px
        --cs-select-icon 26px
        &:not(:last-of-type)
            margin-bottom 20px
        & > div
            display inline-block
            vertical-align middle
            input[type='radio']
                &::after
                    display none
                &::before
                    display none
        &-label
            text-align right
            width 140px
            color #444
            margin-right 30px
        select
            width 198px
            height 100%
        .cs-input
            width 200px
            height 30px
            &::placeholder
                color #999
        .question-icon
            width 16px
            height 16px
            margin-left 10px
            vertical-align middle
        textarea.cs-input
            border none
            background #f0f0f0
            resize none
            height 100px
            padding 8px
            width 300px
            border-radius 5px
.contact-dialog-content
    padding 27px 30px 30px 30px
    .form-item
        font-size 24px
        &-label
            width 144px
            margin-right 40px
            color #000
            text-align right
        &:not(:last-of-type)
            margin-bottom 23px
        div
            display inline-block
            vertical-align middle
        .cs-input
            width 400px
            border 1px solid #979797
            border-radius 4px
            text-indent 10px
            vertical-align middle
.dropdown-menu
  min-width 48px
  width 48px
  li
    a
      padding 3px 6px
</style>
